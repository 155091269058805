// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-writer-js": () => import("./../../../src/templates/writer.js" /* webpackChunkName: "component---src-templates-writer-js" */)
}

